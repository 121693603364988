import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Modules from "../components/Modules";
import Hero from "../components/Hero";

export default function Entry_withHero({ data }) {
  const entry = data.craft.entries[0];
  const modules = data.craft.entries[0].modules;

  return (
    <Layout heroVariation={entry.heroVariation}>
      <SEO>
        {parse(data.craft.seomatic.metaTitleContainer)}
        {parse(data.craft.seomatic.metaTagContainer)}
        {parse(data.craft.seomatic.metaLinkContainer)}
        {parse(JSON.parse(data.craft.seomatic.metaScriptContainer).script)}
        {parse(data.craft.seomatic.metaJsonLdContainer)}
      </SEO>
      <Hero
        heroVariation={entry.heroVariation}
        heroButtonText={entry.heroButtonText}
        heroButtonType={entry.heroButtonType}
        heroInternalButtonUrl={entry.heroInternalButtonUrl}
        heroExternalButtonUrl={entry.heroExternalButtonUrl}
        heroBackgroundImage={entry.heroBackgroundImage}
        heroImageOverlay={entry.heroImageOverlay}
        heroBackgroundVideo={entry.heroBackgroundVideo}
        heroHeading={entry.heroHeading}
        heroSubheading={entry.heroSubheading}
        heroBottomSpacing={entry.heroBottomSpacing}
        heroShowDarkVariant={entry.heroShowDarkVariant}
      />
      {modules && <Modules modules={modules} />}
    </Layout>
  );
}

export const query = graphql`
  query($id: [Craft_QueryArgument], $uri: String) {
    craft {
      entries(id: $id) {
        ... on Craft_pages_withHero_Entry {
          id
          heroVariation
          heroButtonText
          heroButtonType
          heroBottomSpacing
          heroInternalButtonUrl {
            uri
          }
          heroExternalButtonUrl
          heroBackgroundImage {
            ... on Craft_uploads_Asset {
              id
              xLarge: url(width: 1600, height: 500, position: "centerCenter")
              large: url(width: 1024, height: 500, position: "centerCenter")
              medium: url(width: 700, height: 500, position: "centerCenter")
              small: url(width: 500, height: 500, position: "centerCenter")
              xLarge_2x: url(
                width: 3200
                height: 1000
                position: "centerCenter"
              )
              large_2x: url(width: 2052, height: 1000, position: "centerCenter")
              medium_2x: url(
                width: 1400
                height: 1000
                position: "centerCenter"
              )
              small_2x: url(width: 1000, height: 1000, position: "centerCenter")
              altText
            }
          }
          heroImageOverlay {
            ... on Craft_uploads_Asset {
              id
              src1x: url(width: 500, position: "centerCenter")
              src2x: url(width: 1000, position: "centerCenter")
              altText
            }
          }
          heroBackgroundVideo {
            ... on Craft_uploads_Asset {
              id
              url
            }
          }
          heroHeading
          heroSubheading
          title
          uri
          parent {
            id
            uri
            title
          }
          heroShowDarkVariant
          modules {
            ...textBlockData
            ...imageGalleryData
            ...fullwidthImageData
            ...imageGridData
            ...teamMemberGridData
            ...blogCardGridData
            ...ctaCardData
            ...imageSliderData
            ...logoGridData
            ...workCardData
            ...rollupCtaData
            ...selectableWorkCardsData
            ...imageWithContentData
            ...videoData
            ...hubspotEmbedData
            ...twoColumnTextBlockData
            ...industryResourcesGridData
            ...timelineData
            ...imageData
          }
        }
      }

      seomatic(uri: $uri) {
        metaTitleContainer
        metaTagContainer
        metaLinkContainer
        metaScriptContainer
        metaJsonLdContainer
      }
    }
  }
`;
