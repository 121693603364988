import React, { useState } from "react";
import { Link } from "gatsby";
import styles from "./index.module.css";
import Arrow from "../../images/arrow.svg";
import VideoPlayer from "../VideoPlayer";
import useViewportSizes from "use-viewport-sizes";

export default function Hero(props) {
  const {
    heroBackgroundImage,
    heroImageOverlay,
    heroBackgroundVideo,
    heroButtonText,
    heroButtonType,
    heroExternalButtonUrl,
    heroInternalButtonUrl,
    heroHeading,
    heroSubheading,
    heroVariation,
    heroBottomSpacing,
    heroShowDarkVariant
  } = props;
  const [vpWidth] = useViewportSizes();

  function scrollToSection(e) {
    const href = e.target.getAttribute("href");
    if (href.startsWith("#")) {
      e.preventDefault();
      const top = document.querySelector(`${href}`).getBoundingClientRect().top;
      window.scrollTo({
        top: top - 100,
        behavior: "smooth"
      });
    }
  }

  const darkStyle = heroShowDarkVariant === true ? styles.Hero__dark : "";
  const [isMuted, setMuted] = useState(true)

  return (
    <div className={darkStyle}>
      <div
        className={`
          ${styles.Hero} ${styles[`Hero` + heroVariation]}
          ${styles[`HeroBottom${heroBottomSpacing}`]}
          ${heroBackgroundVideo.length > 0 && "aspect-video"}
        `}
      >
        {heroBackgroundImage[0] && (
          <picture className={styles.Hero__Image}>
            <source
              media="(min-width: 1024px)"
              srcSet={` ${heroBackgroundImage[0].xLarge +
                " 1x"}, ${heroBackgroundImage[0].xLarge_2x + " 2x"} `}
            />
            <source
              media="(min-width: 700px)"
              srcSet={` ${heroBackgroundImage[0].large +
                " 1x"}, ${heroBackgroundImage[0].large_2x + " 2x"} `}
            />
            <source
              media="(min-width: 500px)"
              srcSet={` ${heroBackgroundImage[0].medium +
                " 1x"}, ${heroBackgroundImage[0].medium_2x + " 2x"} `}
            />
            <img
              src={encodeURI(heroBackgroundImage[0].small)}
              srcSet={` ${heroBackgroundImage[0].small +
                " 1x"}, ${heroBackgroundImage[0].small_2x + " 2x"} `}
              alt={heroBackgroundImage[0].altText}
            />
          </picture>
        )}

        {heroImageOverlay[0] && (
          <div className={styles.Hero__Overlay}>
            <img
              src={heroImageOverlay[0].src1x}
              srcSet={`${heroImageOverlay[0].src2x + " 2x"} `}
              alt=""
            />
          </div>
        )}
        {heroBackgroundVideo.length > 0 && (
          <>
            <div className={styles.Hero__Video}>
              <VideoPlayer
                src={heroBackgroundVideo[0].url}
                options={{
                  autoplay: true,
                  muted: true,
                  controls: false,
                  loop: { active: true }
                }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 32, left: 32 }}>
              <button
                aria-label="Mute or unmute video"
                onClick={() => {
                  window.video.muted = !window.video.muted;
                  setMuted(!isMuted)
                }}
                style={{ borderRadius: 99999999, width: 48, height: 48, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1b8fba", color: "white" }}
              >

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{ width: 32, height: 32 }}
                  className="size-6"
                >
                  {!isMuted && (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                    />
                  )}
                  {isMuted && (
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                  />
                  )}
                </svg>
              </button>
            </div>
          </>
        )}

        {heroHeading && (
          <hgroup className="container container--1132px">
            <div className={`${styles.Hero__CTAWrap} antialiased`}>
              <h2
                dangerouslySetInnerHTML={{ __html: heroHeading }}
                className={`${styles.Hero__Copy} ${styles.Hero__Title}`}
              ></h2>

              {heroSubheading && (
                <h3
                  dangerouslySetInnerHTML={{ __html: heroSubheading }}
                  className={`${styles.Hero__Copy} ${styles.Hero__SubTitle}`}
                  data-hero-text
                ></h3>
              )}

              {heroExternalButtonUrl && (
                <a
                  href={heroExternalButtonUrl}
                  onClick={e => scrollToSection(e)}
                  className={`button button--icon button--${heroButtonType}`}
                  role="button"
                >
                  {heroButtonText}
                  <Arrow />
                </a>
              )}

              {heroInternalButtonUrl.length > 0 && (
                <Link to={`${heroInternalButtonUrl[0].uri}/`}>
                  <div
                    role="presentation"
                    className={`button button--icon button--${heroButtonType}`}
                  >
                    {heroButtonText}
                    <Arrow />
                  </div>
                </Link>
              )}
            </div>
          </hgroup>
        )}
      </div>
    </div>
  );
}

Hero.defaultProps = {
  heroButtonText: "Default Button Text",
  heroInternalButtonUrl: [],
  heroExternalButtonUrl: "/",
  heroBackgroundImage: [
    {
      xLarge: "https://source.unsplash.com/random/1600x526",
      large: "https://source.unsplash.com/random/1024x526",
      medium: "https://source.unsplash.com/random/700x526",
      small: "https://source.unsplash.com/random/500x526",
      xLarge_2x: "https://source.unsplash.com/random/3200x1052",
      large_2x: "https://source.unsplash.com/random/2052x1052",
      medium_2x: "https://source.unsplash.com/random/1400x1052",
      small_2x: "https://source.unsplash.com/random/1000x1052"
    }
  ],
  heroImageOverlay: [],
  heroBackgroundVideo: [],
  heroHeading: "Default Hero Heading.",
  heroSubheading: "Default Hero Subheading.",
  heroVariation: "Default",
  heroBottomSpacing: "default"
};
